import { createFeature, createReducer } from '@ngrx/store';
import {
  backlogsPermissionState, PermissionState, projectSettingPermissionState, releaseDetailPermissionState, storylinePermissionState,
  taskFinderPermissionState, taskPermissionState, epicPermissionState, taskTemplatePermissionState, releasesPermissionState,
  activeBoardPermissionState, releaseNotePermissionState, sprintPermissionState
} from './permission.models';
import { featureKey } from './permission.selectors';

const initialState: PermissionState = {
  task: taskPermissionState,
  backlogs: backlogsPermissionState,
  releaseDetails: releaseDetailPermissionState,
  taskFinder: taskFinderPermissionState,
  storyline: storylinePermissionState,
  projectSetting: projectSettingPermissionState,
  epic: epicPermissionState,
  taskTemplate: taskTemplatePermissionState,
  releases: releasesPermissionState,
  activeBoard: activeBoardPermissionState,
  releaseNote: releaseNotePermissionState,
  sprint: sprintPermissionState,
};

const permissionReducer = createReducer(
  initialState,
);

export const permissionFeature = createFeature({
  name: featureKey,
  reducer: permissionReducer
});
