
export const environment = {
  production: true,
  mainDomain: 'staging.xcorp.app',
  clientUrl: 'https://staging.xcorp.app',
  apiUrl: 'https://api-staging.xperc.com',
  s3Url: 'https://staging-xcorp-upload.s3.ap-southeast-1.amazonaws.com',
  googleClientId: '749290038722-r8ti5huq0mn9lbanlfs7gsggukbnqk6b.apps.googleusercontent.com',
  stripeKey: 'pk_live_51KtUFRDH7WUIgCA0Q3J1teuGiKoTmWH0SffEXhuWcxEWcZ4zR1STpd9nCjS15EX1x923URZNqoT3Jb55v31RmSPl004kUQQPQl',
  froalaKey: 'yDC5hH4I4C11D7D7F5C4g1JWSDBCQG1ZGDf1C1d2JXDAAOZWJhE5B4E4H3F2H3B8A4C4D4==',
  cmsUrl: 'https://cms-api.xcorp.app',
  env: 'staging',
  version: '1.2.7',
  chatGpt: {
    apiKey: "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",
    endpoint: "https://api.openai.com/v1",
    model: 'gpt-3.5-turbo-1106',
    maxToken: 255
  }
};
