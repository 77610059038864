import { Role } from './role.model';

export interface Permisson {
  id: number;
  code: string;
  name: string;
  parentId: number;
  dynamicFieldHash: string;
  dynamicFieldId: number;
}

export interface RolePermissionItem {
  id: number;
  permission: string;
  activeFlg: boolean;
  permissionId: number;
  disabled?: boolean;
}

export interface RolePermission {
  role: Role;
  permissions: RolePermissionItem[];
}

export interface PermissionSettingItem {
  code: string;
  isCollapsed?: boolean;
  isChecked?: boolean;
  permissions?: PermissionSettingItem[];
  id: number;

  data?: RolePermissionItem[];
  parent?: PermissionSettingItem;
  hide?: boolean;
}

export interface PermissionCache {
  code?: PermissionCodeCache;
  version?: number;
  modifiedAt?: string;
  companyId?: number;
}

export interface PermissionCacheRequest {
  codes: PermissionCodeCache[];
}

export enum PermissionSettingStatus {
  checked= 'checked',
  indeterminate= 'indeterminate',
  none= ''
}

export enum PermissionAction {
  VIEW= 'VIEW',
  EDIT= 'EDIT',
  VIEW_OTHER= 'VIEW_OTHER',
  EDIT_OTHER= 'EDIT_OTHER',
  VIEW_ADVANCE= 'VIEW_ADVANCE',
  EDIT_ADVANCE= 'EDIT_ADVANCE',
  VIEW_OTHER_ADVANCE= 'VIEW_OTHER_ADVANCE',
  EDIT_OTHER_ADVANCE= 'EDIT_OTHER_ADVANCE',
}
export enum PermissionActionChecker {
  VIEW= `_${PermissionAction.VIEW}$`,
  EDIT= `_${PermissionAction.EDIT}$`,
  VIEW_OTHER= `_${PermissionAction.VIEW_OTHER}$`,
  EDIT_OTHER= `_${PermissionAction.EDIT_OTHER}$`,
  VIEW_ADVANCE= `_${PermissionAction.VIEW_ADVANCE}$`,
  EDIT_ADVANCE= `_${PermissionAction.EDIT_ADVANCE}$`,
  VIEW_OTHER_ADVANCE= `_${PermissionAction.VIEW_OTHER_ADVANCE}$`,
  EDIT_OTHER_ADVANCE= `_${PermissionAction.EDIT_OTHER_ADVANCE}$`,
}

export enum PermissionCodeCache {
  ROLE = 'Role',
  PERMISSION = 'Permission',
  ROLE_PERMISSION = 'RolePermission'
}
